import React from "react";
import classes from "./Card.module.css";

type CardProps = {
  children: React.ReactNode;
  style?: React.CSSProperties;
  customClass?: string;
};

export const Card: React.FC<CardProps> = ({ style, children, customClass }) => {
  return (
    <div className={`${classes.card} ${customClass}`} style={style} data-component="card">
      {children}
    </div>
  );
};
