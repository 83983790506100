import { yupResolver } from "@hookform/resolvers/yup";
import { Modal } from "@metlife-one-opps/modal";
import React from "react";
import * as yup from "yup";
import { MANDATORY, MOBILE_MIN_LENGTH } from "../../constants";
import { useForm } from "react-hook-form";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import { MetLifeInput } from "@metlife-one-opps/metlife-input";
export const UpdateMobileNumberModal = ({
  showModal = false,
  onClose = () => {},
  onSubmit = (data: any) => {},
  initialValue = "",
}) => {
  const updateSchema = yup.object().shape({
    updateMobileNumber: yup.string().required(MANDATORY).min(10, MOBILE_MIN_LENGTH),
  });

  const {
    handleSubmit,
    control,
    reset,
    formState: { errors },
  } = useForm({
    mode: "all",
    defaultValues: {
      updateMobileNumber: initialValue,
    },
    resolver: yupResolver(updateSchema),
  });

  const onSubmitForm = (data: any) => {
    onSubmit(data);
  };
  return (
    <Modal
      data-testid="update-mobile-number-modal"
      isVisible={showModal}
      showModalHeader={false}
    >
      <div className="update-mobile-modal-content">
        <div className="modal-body">
          <form
            onSubmit={handleSubmit(onSubmitForm)}
            data-testid="UpdateMobileNumberModal__form"
          >
            <div className="modal-body-heading">
              <h3>Edit Phone Number</h3>
            </div>
            <div className="modal-body-content">
              <MetLifeInput
                data-testid="UpdateMobileNumberModal__mobileNumber"
                containerCustomClass="ConfirmPayment__update-mobile-number"
                control={control}
                prePopulate={"+977"}
                type="phone"
                maxLength={10}
                inputHasValue={!!initialValue}
                name={"updateMobileNumber"}
                placeholder="Enter Your Mobile Number"
                isError={!!errors?.updateMobileNumber?.message}
                errMessage={errors?.updateMobileNumber?.message}
              />
            </div>
            <div className="modal-body-footer">
              <MetLifeButton
                data-testid="update-mobile-number-cancel"
                type="button"
                variant="secondary"
                onClick={() => {
                  reset();
                  onClose();
                }}
              >
                Cancel
              </MetLifeButton>
              <MetLifeButton data-testid="update-mobile-number-submit">
                Update
              </MetLifeButton>
            </div>
          </form>
        </div>
      </div>
    </Modal>
  );
};
