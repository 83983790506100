import React, { useEffect, useState } from "react";
import { AlertPopup } from "@metlife-one-opps/alert-popup";
import { Card } from "@metlife-one-opps/card";
import { useLoader } from "@metlife-one-opps/hooks";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import { MetLifeGradientRow } from "@metlife-one-opps/metlife-gradient-row";
import { CloseIcon, PageContainer } from "@metlife-one-opps/page-container";
import {
  finishPayment,
  receiptGenerate,
  refreshToken as requestRefreshToken,
  revokeToken,
} from "@metlife-one-opps/services";
import moment from "moment";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { CheckCircle } from "../../../assets";
import { RECEIPT_FILE_NAME, RECEIPT_FILE_TYPE } from "../../../constants";
import "./PaymentDone.css";
import qs from "qs";

export const PaymentDone = (props: any) => {
  const [logoutPopUp, setLogoutPopUp] = useState(false);

  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const { state: routeData } = useLocation();
  const [policyDetails, setPolicyDetails] = useState<any>({});
  const [isToastVisible, setIsToastVisible] = useState(true);

  const handleCloseToast = () => {
    setIsToastVisible(false);
  };
  const userPolicyData = JSON.parse(
    window.localStorage.getItem("@user-policy-data") || "{}"
  );
  const [searchParams] = useSearchParams();
  const ref_t = searchParams.get("refreshToken");
  const refreshToken = ref_t?.includes("?") ? ref_t.split("?")[0] : ref_t;
  const selectedPaymentVendor: any = userPolicyData?.vendorName ?? "";
  const transactionId: any = userPolicyData?.transactionId ?? "";
  window.document.title = "Download Receipt - Metlife Nepal OPP";

  const receiptHandler = async (action: string) => {
    const { base64Response }: any = await receiptGeneration();
    if (base64Response) {
      if (action === "print") {
        printReceipt(base64Response);
      }
      if (action === "download") {
        downloadReceipt(base64Response);
      }
    }
  };

  const receiptGeneration = async () => {
    const data_policy = JSON.parse(
      window.localStorage.getItem("@user-access") || "{}"
    );
    let base64Response = "";
    try {
      setLoading(true);
      const response: any = await receiptGenerate(
        {
          paymentId: transactionId,
        },
        {
          headers: {
            Authorization: `Bearer ${data_policy?.token}`,
            "X-Bearer-Issuer": window?.__RUNTIME_CONFIG__?.REACT_APP_API_ISSUER,
          },
        }
      );
      if (response.status === 200) {
        base64Response = response?.data?.data?.fileData;
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log("Error while generating receipt file.", error);
    }
    return { base64Response: base64Response };
  };

  const downloadReceipt = async (base64Data: string) => {
    const fileName = `${RECEIPT_FILE_NAME}.${RECEIPT_FILE_TYPE}`;
    const linkSource = `data:application/pdf;base64,${base64Data}`;
    const downloadLink = document.createElement("a");
    downloadLink.href = linkSource;
    downloadLink.download = fileName;
    downloadLink.click();
  };

  const printReceipt = async (base64Data: string) => {
    const pdfDataUrl = `data:application/pdf;base64,${base64Data}`;
    const printWindow = window.open();
    if (printWindow) {
      printWindow.document.write(
        `<embed width='100%' height='100%' src='${pdfDataUrl}' type='application/pdf' />`
      );
      printWindow.document.close();
      printWindow.onload = function () {
        printWindow.print();
      };
    } else {
      console.error("Failed to open new window");
    }
  };

  const redirectToFailed = () => {
    localStorage.clear();
    window.location.replace(
      `${window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL}?paymentFailed=true`
    );
  };

  const requestNewAccessToken = async () => {
    let data = qs.stringify({
      grant_type: "refresh_token",
      refresh_token: refreshToken,
    });

    return await requestRefreshToken(data)
      .then((res) => {
        localStorage.setItem(
          "@user-access",
          JSON.stringify({
            refresh_token: res.data.refresh_token,
            token: res.data.access_token,
            expiresIn: moment(new Date())
              .add(res.data.expires_in, "minutes")
              .toISOString(),
          })
        );
        return true;
      })
      .catch((error) => {
        return false;
      });
  };

  const getPaymentData = async () => {
    try {
      setLoading(true);
      const res = await finishPayment({
        transactionId: userPolicyData?.transactionId,
      });
      setLoading(false);
      if (res?.status === 200) {
        const paymentStatus = res?.data?.data?.paymentStatus;
        if (paymentStatus === "FAILED" || paymentStatus === "UNKNOWN") {
          redirectToFailed();
        }
        setPolicyDetails(res?.data?.data ?? {});
      }
    } catch (error) {
      console.log("Error while finishing the payment", error);
      setLoading(false);
    }
  };

  const logoutHandler = async () => {
    try {
      setLoading(true);
      const res = await revokeToken();
      setLoading(false);
      if (res && res?.status === 200) {
        localStorage.clear();
        navigate("/");
      }
    } catch (error) {
      setLoading(false);
      localStorage.clear();
      navigate("/");
      console.log("Error | Revoke Token", error);
    }
  };

  useEffect(() => {
    async function initialisePaymentDone() {
      if ((await requestNewAccessToken()) === true) {
        await getPaymentData();
      }
    }
    initialisePaymentDone();
  }, []);

  return (
    <PageContainer
      customClassName="OTP_Title"
      onLogout={() => {
        setLogoutPopUp(true);
      }}
    >
      <div className="PaymentDone__container">
        <div className="PaymentDone__heading">
          <MetLifeGradientRow />
          {/* This is temporary solution */}
          <h2>Online Payment Through - {selectedPaymentVendor}</h2>
        </div>
        <div>
          {isToastVisible ? (
            <div
              className="toast-message success"
              id="success-message"
              data-testid="payment-done-success-toast"
            >
              <div className="toast-content">
                <div className="icon">
                  <CheckCircle height={24} width={24} fillColor="#00ACA0" />
                </div>
                <div className="description">
                  Thank you for your payment of{" "}
                  <a>
                    <strong>{policyDetails?.paymentAmount}</strong> |{" "}
                    <strong>Transaction ID: {transactionId}</strong>
                  </a>
                </div>
              </div>

              <div
                data-testid="close-success-toast"
                className="close"
                onClick={handleCloseToast}
              >
                <CloseIcon height={14} width={14} />
              </div>
            </div>
          ) : (
            <div className="empty_spacing"></div> // Placeholder element to maintain spacing
          )}
        </div>
        <form onSubmit={(e) => e.preventDefault()}>
          <Card customClass="PaymentDone__card">
            <div className="PaymentDone__card-contents">
              <div className="card-heading">Policy Details</div>
              <div className="card-content">
                <div className="content-row">
                  <div className="col-1">
                    <div className="label">Policy Number</div>
                    <div className="value">
                      <div> {policyDetails?.policyNumber}</div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="label">Premium Amount</div>
                    <div className="value">
                      <div>{policyDetails?.paymentAmount}</div>
                    </div>
                  </div>
                </div>
                <div className="content-row">
                  <div className="col-1">
                    <div className="label">Policy Owner's Name</div>
                    <div className="value">
                      <div> {policyDetails?.policyOwnerName}</div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="label">Payment For</div>
                    <div className="value">
                      <div> {policyDetails?.paymentType}</div>
                    </div>
                  </div>
                </div>
                <div className="content-row">
                  <div className="col-1">
                    <div className="label">Policy Status</div>
                    <div className="value">
                      <div>{policyDetails?.policyStatus}</div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="label">Due Date</div>
                    <div className="value">
                      <div>
                        {moment(policyDetails?.dueDate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-row">
                  <div className="col-1">
                    <div className="label">Contact Number</div>
                    <div className="value">
                      <div>{policyDetails?.contactNumber}</div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="label">Payment Date</div>
                    <div className="value">
                      <div>
                        {moment(policyDetails?.paymentDate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="content-row">
                  <div className="col-1">
                    <div className="label">Address</div>
                    <div className="value">
                      <div> {policyDetails?.address}</div>
                    </div>
                  </div>
                  <div className="col-2">
                    {/* This is temporary solution */}
                    <div className="label">Next Due Date</div>
                    <div className="value">
                      <div>{policyDetails?.nextDueDate ?? "N/A"}</div>
                    </div>
                  </div>
                </div>
                <div className="content-row">
                  <div className="col-1">
                    <div className="label">Maturity/Term Expiry Date</div>
                    <div className="value">
                      <div>
                        {moment(policyDetails?.maturityDate).format("DD/MM/YYYY")}
                      </div>
                    </div>
                  </div>
                  <div className="col-2">
                    <div className="label">Agent Code</div>
                    <div className="value">
                      <div>{policyDetails?.agentCode}</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </Card>
          <div className="PaymentDone__agency-details">
            <div className="agency-code">
              Agency/Financial Associate Code: {policyDetails?.agentCode}
            </div>
            <div className="agency-disclaimer">
              This is a computer generated receipt issued by American Life Insurance
              Company (MetLife) and does not require any signature. Should the Policy
              is restored at any time by acceptance of premium after the same is due
              and payable, such restoration shall not create an obligation or
              precedence for waiving any conditions of the pollcy in regard to
              subsequent non-payment of any premium on the day it falls due. No
              person except an officer of MelLife as designated in the Policy is
              authorized to make, alter or discharge contracts or waive forfeitures.
              If the said amount is not accepted by MetLife for the purpose offered,
              it will be returned to the depositor in usual course of Business.
            </div>
          </div>
          <div className="PaymentDone__btn-group">
            <MetLifeButton
              data-testid="PaymentDone__download-receipt-btn"
              className="download-btn"
              variant="primary"
              type="button"
              onClick={() => receiptHandler("download")}
            >
              Download Receipt
            </MetLifeButton>
            <MetLifeButton
              data-testid="PaymentDone__print-receipt-btn"
              className="print-btn"
              variant="secondary"
              type="button"
              onClick={() => receiptHandler("print")}
            >
              Print Receipt
            </MetLifeButton>
          </div>
        </form>

        {props.testlogoutHandler && (
          //unit-testing only
          <>
            <button onClick={logoutHandler} data-testid="test-logout-handler-btn" />
            <button
              onClick={() => setLogoutPopUp(true)}
              data-testid="test-logout-show-modal-btn"
            />
          </>
        )}

        <AlertPopup
          data-testid="auth-logout-popup"
          alertWrapperCustomClass="auth-popups-custom-modal-wrapper-logout"
          showSecodaryBtn
          isVisible={logoutPopUp}
          onCloseAlert={() => {}}
          onPressPrimary={logoutHandler}
          onPressSecondary={() => setLogoutPopUp(false)}
          primaryBtnText="Yes"
          secondaryBtnText="No"
          heading={"Are you sure you want to logout?"}
          description={""}
          description2=""
        />
      </div>
    </PageContainer>
  );
};
