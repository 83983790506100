import { yupResolver } from "@hookform/resolvers/yup";
import { AlertPopup } from "@metlife-one-opps/alert-popup";
import config from "@metlife-one-opps/app/src/config";
import { Card } from "@metlife-one-opps/card";
import { useLoader } from "@metlife-one-opps/hooks";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import { MetLifeGradientRow } from "@metlife-one-opps/metlife-gradient-row";
import { MetlifeOTPInput } from "@metlife-one-opps/metlife-otp-input";
import { PageContainer } from "@metlife-one-opps/page-container";
import { sendOTP, startPayment, verifyOtp } from "@metlife-one-opps/services";
import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import { useLocation, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import {
  GENERAL_SERVER_ERROR,
  OTP_ERRORS,
  otpStatus as otpStatusCodes,
} from "../../../constants";
import { paymentGatewaysList } from "../../../data";
import "./VerifyOTP.css";
export const otpValidateSchema = Yup.object({
  otp: Yup.string().required(OTP_ERRORS.mandatory).length(6, OTP_ERRORS.invalidOTP),
});

const VerifyOTP = () => {
  const {
    handleSubmit,
    control,
    setValue,
    trigger,
    setError,
    formState: { errors },
  } = useForm({
    mode: "all",
    resolver: yupResolver(otpValidateSchema),
    defaultValues: {
      otp: "",
    },
  });
  const { setLoading } = useLoader();
  const navigate = useNavigate();
  const { state } = useLocation();
  const uuid = state?.uuid;
  const phoneNumber = state?.msisdn;
  const otpStatus = state?.otpStatus;
  const [triggerResendTimer, setTriggerResendTimer] = useState(true);
  const [isOTPAttemptsExceeded, setIsOTPAttemptsExceeded] = useState(false);
  const [timer, setTimer] = useState(config?.otpResendTimeInSeconds);
  const [timerText, setTimerText] = useState("");

  const getTimeoutText = (t: number) => {
    if (t === config.otpResendTimeInSeconds) return "02:00 min";
    const minutes = Math.floor(t / 60)
      .toString()
      .padStart(2, "0");
    const seconds = (t % 60).toString().padStart(2, "0");
    const suffix = t >= 60 ? " min" : " seconds";

    return `${minutes}:${seconds}${suffix}`;
  };

  const startResendOTPCountdown = () => {
    const intval = setInterval(() => {
      setTimer((prevTimerValue) => {
        if (prevTimerValue <= 1) {
          clearInterval(intval);
          setTriggerResendTimer(false);
          return 0;
        } else {
          return prevTimerValue - 1;
        }
      });
    }, 1000);
  };

  useEffect(() => {
    if (timer > 0) {
      setTimerText(getTimeoutText(timer));
    }
  }, [timer]);

  useEffect(() => {
    if (triggerResendTimer) {
      startResendOTPCountdown();
    }
  }, [triggerResendTimer]);

  const onSubmit = async (data: any) => {
    try {
      setLoading(true);
      const resp = await verifyOtp({
        uuid: uuid,
        otpCode: data?.otp.toString(),
      });
      console.log("Verify OTP Response", resp);
      if (resp?.status === 200) {
        let reqPayload = {
          ...state.requestPayload,
          paymentVendor: state.paymentVendor,
        };
        console.log("Request Payload", reqPayload);
        const res = await startPayment(reqPayload);
        setLoading(false);
        if (res?.status === 200) {
          const paymentVendorItem = paymentGatewaysList.find(
            (pvi) => pvi.altText === state?.paymentVendor
          );
          console.log("Payment Vendor Item", paymentVendorItem);
          const userAccessJSON = localStorage.getItem("@user-access") || "";
          const userAccessData = userAccessJSON ? JSON.parse(userAccessJSON) : {};
          localStorage.setItem(
            "@user-policy-data",
            JSON.stringify({
              vendorName: paymentVendorItem?.value,
              transactionId: state?.paymentId,
              accessToken: userAccessData?.token,
              refresh_token: userAccessData?.refresh_token,
            })
          );

          navigate("/payment-overview", {
            state: {
              item: state?.paymentVendor,
              policy: state?.policy,
              otherAmount: state?.otherAmount,
              selectedPayment: {
                ...state?.selectedPayment,
                amount: state?.payableAmount,
              },
              paymentId: state?.paymentId,
            },
          });
        }
      }
      setLoading(false);
    } catch (error: any) {
      const errorStatusCode: any = error?.response?.status;
      const isOtpLimitExceeded: any =
        error?.response?.data?.errors?.errorCode === "UBC009";

      if (isOtpLimitExceeded) {
        setIsOTPAttemptsExceeded(true);
      } else if (errorStatusCode === 500) {
        setError("otp", {
          type: "server",
          message: OTP_ERRORS.invalidOTP,
        });
      }
      setLoading(false);
      console.log("Verify OTP", error);
    }
  };

  const resendOTP = async () => {
    try {
      setLoading(true);
      const otpSentResponse = await sendOTP({
        uuid: uuid,
      });

      if (
        otpSentResponse?.data?.data?.otpStatus === otpStatusCodes.OTP_SENT ||
        otpSentResponse?.data?.data?.otpStatus === otpStatusCodes.OTP_RESENT
      ) {
        setTimer(config?.otpResendTimeInSeconds);
        setTriggerResendTimer(true);
      }
      setLoading(false);
    } catch (error: any) {
      const errorCode: any = error?.response?.data?.errors?.errorCode;
      if (errorCode === "OTP001") {
        setError("otp", {
          type: "server",
          message: OTP_ERRORS.resendMaxLimitReached,
        });
      } else if (errorCode === "OTP005") {
        setError("otp", {
          type: "server",
          message: OTP_ERRORS.resendServerError,
        });
      } else {
        setError("otp", {
          type: "server",
          message: GENERAL_SERVER_ERROR,
        });
      }
      setLoading(false);
      console.log("error while sending otp", error);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    if (phoneNumber === undefined && otpStatus !== otpStatusCodes.OTP_SENT) {
      navigate("/");
    }
    window.history.replaceState({}, "");
  }, []);

  return (
    <PageContainer customClassName="OTP_Title">
      <div className="VerifyOTP__wrapper">
        <form onSubmit={handleSubmit(onSubmit)} data-testid="VerifyOTP__otpform">
          <div className="VerifyOTP__mainContainer">
            <div className="VerifyOTP__heading">
              <MetLifeGradientRow />
              <h1>Verify Your Account</h1>
            </div>
            <Card customClass="VerifyOTP__form-card">
              <div className="VerifyOTP__form-container">
                <div className="VerifyOTP__otp-input-header">
                  <h4>Enter the 6-digit One-Time Password</h4>
                  <p>
                    A 6-digit one-time password (OTP) has been sent to your
                    registered mobile number ending in {phoneNumber?.slice(-4)}.
                  </p>
                </div>
                {/* create a 6 digit otp input with inline stylings */}
                <div className="VerifyOTP__otp-input">
                  <MetlifeOTPInput
                    data-testid="otp-input"
                    onChange={(otp) => {
                      if (otp) {
                        setValue("otp", otp);
                        trigger("otp", { shouldFocus: true });
                      }
                    }}
                    isError={!!errors?.otp?.message}
                    errMessage={errors?.otp?.message}
                  />
                  <Controller
                    name="otp"
                    control={control}
                    render={() => (
                      <input
                        data-testid="otp-hidden-input"
                        type="hidden"
                        {...control.register("otp")}
                      />
                    )}
                  />
                </div>
                <div>
                  <p
                    className={`VerifyOTP__otp-resend-section ${
                      timer > 0 ? "timer-active" : ""
                    }`}
                  >
                    <button
                      type="button"
                      data-testid="verifyotp-resend-btn"
                      onClick={resendOTP}
                    >
                      Resend OTP
                    </button>
                    <div>in {timerText}</div>
                  </p>
                  <div className="VerifyOTP__otp-resend-note">
                    If you did not receive your verification code after several
                    requests, please contact
                    <a href="tel:+977-159-701-66"> +977 15970166</a>
                  </div>
                </div>
              </div>
            </Card>
            <AlertPopup
              data-testid="VerifyOTP__alert-popup-otp-attempts-exceeded"
              isVisible={isOTPAttemptsExceeded}
              description={OTP_ERRORS.invalidOTPAttempts}
              heading={"OTP Attempts Exceeded."}
              primaryBtnText="Close"
              onPressPrimary={() => {
                setIsOTPAttemptsExceeded(false);
              }}
            />
            <div className="VerifyOTP__submit-button-container">
              <MetLifeButton
                data-testid="VerifyOTP__back-button"
                type="button"
                className="VerifyOTP__submit-button VerifyOTP__back-button"
                variant="secondary"
                onClick={() => {
                  navigate(-1);
                }}
              >
                Back
              </MetLifeButton>
              <MetLifeButton
                data-testid="VerifyOTP__submit-button"
                type="submit"
                className="VerifyOTP__submit-button"
              >
                Submit
              </MetLifeButton>
            </div>
          </div>
        </form>
      </div>
    </PageContainer>
  );
};

export default VerifyOTP;
