import React, { useEffect, useState } from "react";
import { PageContainer } from "@metlife-one-opps/page-container";
import "./ConfirmPayment.css";
import { SelectPaymentGateways, StandingInstructions } from "../../components";
import { useLocation, useSearchParams } from "react-router-dom";
import { ONLINE_PAYMENT, STANDING_INSTRUCTION } from "../../../constants";
import { AlertPopup } from "@metlife-one-opps/alert-popup";
import { useAuth } from "@metlife-one-opps/hooks";
import { paymentFailedMessage } from "../../../data";

const ConfirmPayment = () => {
  window.document.title =
    "Select Payment Amount and Payment Gateway - Metlife Nepal OPP";
  const { state } = useLocation();
  const { paymentType } = state ?? {};
  const [searchParams] = useSearchParams();
  const { logout } = useAuth();
  const mandateSetSuccess = searchParams.get("status") === "success";
  const mandateSetFailed = searchParams.get("status") === "failed";
  const paymentTypeParam = searchParams.get("paymentType");
  const policyNumberParam = searchParams.get("policyNumber");
  const [standingInstructionState, setStandingInstructionState] = useState(state);

  useEffect(() => {
    if (policyNumberParam) {
      setStandingInstructionState({
        ...standingInstructionState,
        isStandingInstructionsSet: !!mandateSetSuccess,
        policy: { policyNumber: policyNumberParam },
        paymentType: STANDING_INSTRUCTION,
      });
    }
  }, [mandateSetSuccess, policyNumberParam]);
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <PageContainer>
      <div className="ConfirmPayment_mainContainer">
        {paymentType === ONLINE_PAYMENT &&
          paymentTypeParam !== STANDING_INSTRUCTION && (
            <SelectPaymentGateways
              data-testid="select-payment-gateway"
              state={state}
            />
          )}
        {(paymentType === STANDING_INSTRUCTION ||
          paymentTypeParam === STANDING_INSTRUCTION) && (
          <StandingInstructions
            data-testid="standing-instructions"
            state={standingInstructionState}
          />
        )}
        <AlertPopup
          data-testid="PolicyDetails__alert-popup-payment-failed"
          alertWrapperCustomClass="auth-popups-custom-modal-wrapper-logout"
          isVisible={mandateSetFailed}
          description={
            <span>
              {paymentFailedMessage.line1}
              <strong>{paymentFailedMessage.contactNumber}</strong>
              {paymentFailedMessage.line2}
            </span>
          }
          heading="Transaction Failed"
          primaryBtnText="Go back to Login"
          onPressPrimary={() => {
            logout();
          }}
        />
        <AlertPopup
          alertWrapperCustomClass="auth-popups-custom-modal-wrapper-logout"
          heading="Your Request was successfully submitted"
          description="Your request for standing instruction (SI) has been placed with your bank account."
          isVisible={mandateSetSuccess}
          onCloseAlert={() => {}}
          onPressPrimary={() => {
            logout();
          }}
          primaryBtnText="Go back to Login"
        />
      </div>
    </PageContainer>
  );
};

export default ConfirmPayment;
