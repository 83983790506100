import React, { useState, useEffect } from "react";
import countriesRegions from "../../data/region_countries.json";
import classes from "./MetlifeCountrySelect.module.css";
type MetlifeCountrySelectProps = {
  selectedCountry?: string;
  onSelect?: (country: string) => void;
};

export const MetlifeCountrySelect: React.FC<MetlifeCountrySelectProps> = ({
  selectedCountry = "Nepal",
  onSelect = () => {},
}) => {
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <div className={classes.container}>
      <div className={isMobile ? classes.mobileWrapper : classes.innerWrapper}>
        <button
          className={isMobile ? classes.mobileSelector : classes.selector}
          data-testid="metlife-country-select"
        >
          <div className={classes.country}>
            {isMobile ? <span>Country</span> : null}

            <div
              className={`${classes.countryFlag} ${
                classes["country-flag-" + selectedCountry.replace(/ /g, "-")]
              }`}
            ></div>
            {isMobile ? null : (
              <div className={classes.countryName1}>{selectedCountry}</div>
            )}
          </div>
          <div className={classes.caretIcon}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="11"
              viewBox="0 0 13 12"
              fill="none"
            >
              <path
                d="M3.39031 7.67186C3.1785 7.47196 3.17849 7.13503 3.39029 6.93512L6.21371 4.27021C6.37443 4.11852 6.62557 4.11852 6.78629 4.27021L9.60971 6.93512C9.82151 7.13503 9.8215 7.47196 9.60969 7.67186C9.4145 7.85608 9.10951 7.85607 8.91433 7.67184L6.5 5.393L4.08567 7.67184C3.89049 7.85607 3.5855 7.85608 3.39031 7.67186Z"
                fill="#333333"
              />
            </svg>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="12"
              height="11"
              viewBox="0 0 13 12"
              fill="none"
            >
              <path
                d="M9.60969 4.32814C9.8215 4.52804 9.82151 4.86497 9.60971 5.06488L6.78629 7.72979C6.62557 7.88148 6.37443 7.88148 6.21371 7.72979L3.39029 5.06488C3.17849 4.86497 3.1785 4.52804 3.39031 4.32814C3.5855 4.14392 3.89049 4.14393 4.08567 4.32816L6.5 6.607L8.91433 4.32816C9.10951 4.14393 9.4145 4.14392 9.60969 4.32814Z"
                fill="#333333"
              />
            </svg>
          </div>
        </button>
        <div className={classes.list} data-testid="countries-list">
          {Object.keys(countriesRegions).map((region, index) => {
            return (
              <div key={region} className={classes.countryRegion}>
                <div className={classes.region}>{region}</div>
                {countriesRegions[region].map((country: any, index: number) => {
                  return (
                    <div
                      data-testid={`metlife-select-country-${country.country}`}
                      key={region + country.id}
                      onClick={() => {
                        onSelect(country.country);
                        window.open(
                          country.url || "https://www.metlife.com.np/",
                          "_blank"
                        );
                      }}
                      className={`${classes.country} ${classes.countryInList}`}
                    >
                      <div
                        className={`${classes.countryFlag} ${
                          classes[
                            "country-flag-" + country.country.replace(/ /g, "-")
                          ]
                        }`}
                      ></div>
                      <div className={classes.countryName}>{country.country}</div>
                    </div>
                  );
                })}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
