import React, { useState, useEffect } from "react";
import classes from "./AppFooter.module.css";
import footerMenuLinks from "../../data/footer-menu.json";
import { MetlifeCountrySelect } from "../MetlifeCountrySelect/MetlifeCountrySelect";
import { FacebookIcon, LinkedInIcon, MetLifeLogo, YoutubeIcon } from "../../assets";

type AppFooterProps = {
  children?: React.ReactElement;
};

export const AppFooter: React.FC<AppFooterProps> = () => {
  const [selectedCountry, setSelectedCountry] = useState("Nepal");
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 769);
    };

    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  return (
    <footer className={isMobile ? classes.mobileContainer : classes.container}>
      {/* Render different designs based on isMobile */}
      {isMobile ? (
        <div className={classes.mobileInnerContainer}>
          <div className={classes.mobileCountryTab}>
            <div className={classes.dropdown}>
              <MetlifeCountrySelect
                selectedCountry={selectedCountry}
                onSelect={(v) => setSelectedCountry(v)}
              />
            </div>
            <div className={classes.horizontalDivider}></div>
            <div className={classes.worldwide}>
              <a target="_blank" href="https://www.metlife.com/">
                MetLife Worldwide
              </a>
            </div>
          </div>
          <div className={classes.mobileLogos}>
            <div>
              <MetLifeLogo width="122" />
            </div>
            <div>
              <div className={classes.socialMediaLinks}>
                <a
                  target="_blank"
                  href="https://www.facebook.com/metlifenepal/"
                  className={`${classes.brandIcon} ${classes.fbIcon} `}
                >
                  <FacebookIcon height={15} width={15} />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCgjdKXUChZfv65hRk_RgHUw"
                  className={classes.brandIcon}
                >
                  <YoutubeIcon />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/metlife-nepal/"
                  className={classes.brandIcon}
                >
                  <LinkedInIcon />
                </a>
              </div>
            </div>
          </div>
          <div className={classes.contactDetails}>
            <div className={classes.call}>CALL: +977-5970166</div>
            <div className={classes.links}>
              <ul>
                <li className={classes["terms-conditions"]}>
                  <a
                    target="_blank"
                    href="https://www.metlife.com.np/en/terms-and-conditions/"
                  >
                    Terms and conditions
                  </a>
                </li>
                <li className={classes["privacy-statement1"]}>
                  <a
                    target="_blank"
                    href="https://www.metlife.com.np/en/privacy-policy/"
                  >
                    Privacy Statement
                  </a>
                </li>
              </ul>
            </div>
          </div>
          <div className={classes.copyrightStatment}>
            <div className={classes["copyright-info-mobile_width"]}>
              Copyright &copy; 2024 American Life Insurance Company - Nepal. All
              rights reserved.
            </div>{" "}
          </div>
        </div>
      ) : (
        <div className={classes.innerContainer}>
          <section className={classes.footerInfoLinks}>
            {footerMenuLinks.map((fm: any) => (
              <div key={fm.id} className={classes.footerInfoLink}>
                <h3>{fm.name}</h3>
                <ul>
                  {fm.subMenu.map((fsm: any) => (
                    <li key={fsm.id}>
                      <a
                        data-testid={`footer-link-${fsm.id}-${fm.id}`}
                        href={fsm?.link ?? "#"}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {fsm.name}
                      </a>{" "}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </section>
          <section className={classes.footerWrapperMid}>
            <div className={classes.innerMidColumn1}>
              <div className={classes.countrySelectContainer}>
                <div className={classes.countrySelectWrap}>
                  <span className={classes.countryHeading}>Country</span>
                  <MetlifeCountrySelect
                    selectedCountry={selectedCountry}
                    onSelect={(v) => setSelectedCountry(v)}
                  />
                </div>

                <div className={classes.worldwideHeading}>
                  <a target="_blank" href="https://www.metlife.com/">
                    MetLife Worldwide
                  </a>
                </div>
              </div>
            </div>
            <div className={classes.innerMidColumn2}>
              <div className={classes.followUsHeading}>Follow us</div>
              <div className={classes.socialMediaLinks}>
                <a
                  target="_blank"
                  href="https://www.facebook.com/metlifenepal/"
                  className={`${classes.brandIcon} ${classes.fbIcon} `}
                >
                  <FacebookIcon />
                </a>
                <a
                  target="_blank"
                  href="https://www.youtube.com/channel/UCgjdKXUChZfv65hRk_RgHUw"
                  className={classes.brandIcon}
                >
                  <YoutubeIcon />
                </a>
                <a
                  target="_blank"
                  href="https://www.linkedin.com/company/metlife-nepal/"
                  className={classes.brandIcon}
                >
                  <LinkedInIcon />
                </a>
              </div>
            </div>
          </section>
          <div className={classes.sectionDivider} />
          <section className={classes.footerWrapperBottom}>
            <div className={classes.footerLogo}>
              <div className={classes.footerMelifeLogo}>
                <MetLifeLogo />
              </div>
              <div className={classes.callNumber}>CALL: 1660-01-55555</div>
            </div>
            <div className={classes.bottomLinks}>
              <ul>
                <li className={classes["terms-conditions"]}>
                  <a
                    target="_blank"
                    href="https://www.metlife.com.np/en/terms-and-conditions/"
                  >
                    Terms and conditions
                  </a>
                </li>
                <li className={classes["privacy-statement"]}>
                  <a
                    target="_blank"
                    href="https://www.metlife.com.np/en/privacy-policy/"
                  >
                    Privacy Statement
                  </a>
                </li>
                <li className={classes["copyright-info"]}>
                  Copyright &copy; 2024 American Life Insurance Company - Nepal. All
                  rights reserved.
                </li>
              </ul>
              <div className={classes["copyright-info-mobile"]}>
                Copyright &copy; 2024 American Life Insurance Company - Nepal. All
                rights reserved.
              </div>
            </div>
          </section>
        </div>
      )}
    </footer>
  );
};
