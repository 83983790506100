export const PG_SUCCESS_URL = `${window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL}/payment-done`;
export const PG_FAILIURE_URL = `${window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL}?paymentFailed=true`;

export const INPUT_TYPE_PHONE = "phone";
export const INPUT_DATE_PICKER_FORMAT = "yyyy";
export const STANDING_INSTRUCTION = "standing_instruction";
export const ONLINE_PAYMENT = "online_payment";
export const ALERT_TandC = "You must agree to the Terms and Conditions to continue.";
export const TandC_payOnline = "Terms & Conditions For Payment through Online";
export const GENERAL_TERMS =
  "General terms and conditions for Payment through Online:";

export const paymentVendorNames = {
  ESEWA: "ESEWA",
  FONEPAY: "FONEPAY",
  CONNECTIPS: "CONNECTIPS",
  KHALTI: "KHALTI",
  IMEPAY: "IMEPAY",
};
export const SESSION_TIMEOUT_TOAST_MESSAGE =
  "Your session has been expired due to security reasons. Please login again.";
export const INACTIVITY_TIMEOUT_TOAST_MESSAGE =
  "Your session has been expired due to inactivity. Please login again.";

export const REQUEST_SUBMITTED_HEADING = "Your Request was successfully submitted";
export const REQUEST_SUBMITTED_DESC =
  "Your request to update your mobile number has been submitted, our customer representative will contact you shortly.";
export const OPS_CURRENCY = "NPR";

export const productCodes = {
  ESEWA: "EPAYTEST",
  KHALTI: "KPAYTEST",
  IMEPAY: "IPAYTEST",
  FONEPAY: "FONEPAY",
};
export const PAYMENT_SERVICE_ID = "13936_opps-payment-gateway-service";
export const RECEIPT_FILE_NAME = "RECEIPT_PAYMENT_SUCCESSFUL";
export const TERMS_AND_CONDITION_SIGNATURE = "TERMS_AND_CONDITION";
export const RECEIPT_FILE_TYPE = "pdf";
export * from "./field-error-messages";

export const otpStatus = {
  OTP_REQUIRED: "OTP_REQUIRED",
  OTP_VERIFIED: "OTP_VERIFIED",
  OTP_SENT: "SENT",
  OTP_RESENT: "RESENT",
};

export const SI_FAILIURE_URL = `${window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL}/confirm-payment?status=failed&paymentType=standing_instruction`;
export const SI_SUCCESS_URL = `${window.__RUNTIME_CONFIG__.REACT_APP_BASE_URL}/confirm-payment?status=success&paymentType=standing_instruction`;

export const BAD_REQUEST = "BAD_REQUEST";
export const OTP001 = "OTP001";

export const serverErrorHeadings: any = {
  OTP001: "User is blocked.",
  OTP004: "OTP Sent Failed.",
  OTP005: "OTP Sent Failed.",
  PEY: "User is blocked.",
  OTP007: "OTP Attempts Exceeded.",
  MOB001: "Mobile number not found.",
  PLE001: "",
  PSI001: "",
  LGN001: "",
  INTERNAL_ERROR: "Unexpected Error.",
  INVALID_OPERATION: "Unexpected Error.",
  INVALID_STATE: "Unexpected Error.",
};
export const GENERAL_SERVER_ERROR = "Something went wrong. Please try again later.";
export const CANCEL_SI_HEADING =
  "Are you sure you want to cancel the Standing Instruction?";
export const CANCEL_SI_DESC =
  "You can set up a new Standing Instruction anytime after this cancellation.";
export const CANCEL_SI_CONFIRM_BTN_TEXT = "Yes";
export const CANCEL_SI_DECLINE_BTN_TEXT = "No";
export const CANCEL_SI_SUCCESS_TOAST_MSG = "Standing Instruction has been canceled.";
