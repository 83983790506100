import { Modal } from "@metlife-one-opps/modal";
import React from "react";
import classes from "./AlertPopup.module.css";
import { Card } from "@metlife-one-opps/card";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";

type AlertPopupProps = {
  isVisible: boolean;
  onCloseAlert?: () => void;
  description?: React.ReactNode | string | any;
  description2?: string;
  heading?: string;
  showPrimaryBtn?: boolean;
  showSecodaryBtn?: boolean;
  primaryBtnText?: string;
  secondaryBtnText?: string;
  onPressPrimary?: () => void;
  onPressSecondary?: () => void;
  alertWrapperCustomClass?: string;
};

export const AlertPopup: React.FC<AlertPopupProps> = ({
  isVisible = false,
  onCloseAlert,
  description = "Exit Alert Description",
  description2 = "",
  heading = "Exit Alert Heading",
  showPrimaryBtn = true,
  showSecodaryBtn = false,
  primaryBtnText = "Proceed",
  secondaryBtnText = "Discard",
  alertWrapperCustomClass = "",
  onPressPrimary = () => {},
  onPressSecondary = () => {},
}) => {
  const isBothButtonVisible = showPrimaryBtn && showSecodaryBtn;

  return (
    <Modal
      title="Are you sure?"
      showModalHeader={false}
      isVisible={isVisible}
      onClose={onCloseAlert}
    >
      <div className={classes.modalClass} data-component="alert-popup">
        <Card customClass={classes.card}>
          <div className={classes.container}>
            <div className={classes.heading}>
              <h3>{heading || ""}</h3>
            </div>
            <div className={classes.description}>
              <p>{description || ""}</p>
              <p>{description2 || ""}</p>
            </div>
            <div className={classes.footer}>
              <div
                className={classes.btnGroup}
                data-class-identifier="alert-btn-group"
              >
                {showSecodaryBtn && (
                  <MetLifeButton
                    data-single-btn={!isBothButtonVisible}
                    type="button"
                    onClick={onPressSecondary}
                    className={`${classes.secondaryBtn} ${
                      isBothButtonVisible ? classes.bothButtonVisible : ""
                    }`}
                    variant="secondary"
                  >
                    {secondaryBtnText}
                  </MetLifeButton>
                )}
                {showPrimaryBtn && (
                  <MetLifeButton
                    data-single-btn={!isBothButtonVisible}
                    type="button"
                    onClick={onPressPrimary}
                    className={`${classes.primaryBtn} ${
                      isBothButtonVisible ? classes.bothButtonVisible : ""
                    }`}
                  >
                    {primaryBtnText}
                  </MetLifeButton>
                )}
              </div>
            </div>
          </div>
        </Card>
      </div>
    </Modal>
  );
};

export default AlertPopup;
