import { Card } from "@metlife-one-opps/card";
import { useAuth, useLoader } from "@metlife-one-opps/hooks";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import { MetLifeGradientRow } from "@metlife-one-opps/metlife-gradient-row";
import { MetLifeCheckbox } from "@metlife-one-opps/metlife-input";
import { Modal } from "@metlife-one-opps/modal";
import { CloseIcon, PageContainer } from "@metlife-one-opps/page-container";
import { initatePayment, signTermsAndCondtions } from "@metlife-one-opps/services";
import { TermsAndConditions } from "@metlife-one-opps/terms-and-conditions";
import { base62encode, formatAmount } from "@metlife-one-opps/utils";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { InfoCircleIcon } from "../../../assets";
import {
  ALERT_TandC,
  OPS_CURRENCY,
  PG_FAILIURE_URL,
  PG_SUCCESS_URL,
  serverErrorHeadings,
  TERMS_AND_CONDITION_SIGNATURE,
} from "../../../constants";
import { getPayableAmount } from "../../../data";
import "./PaymentOverview.css";
import { AlertPopup } from "@metlife-one-opps/alert-popup";

const PaymentOverview = (props: any) => {
  const navigate = useNavigate();
  const { logout } = useAuth();
  const location = useLocation();
  const routeData = location.state ?? {};
  const [loginError, setLoginError] = useState<any>(null);
  const { item, policy, selectedPayment, paymentId, otherAmount } = routeData ?? {};
  const { setLoading } = useLoader();
  const [formAtrribute, setFormAttribute] = useState<{
    actionURL: string;
    method: "POST";
  }>({
    actionURL: "",
    method: "POST",
  });
  const payableAmount = getPayableAmount(policy, otherAmount);
  const [agreeCheckbox, setAgreeCheckbox] = useState<any>(false);
  const [showTermsConditionsModal, setShowTermsConditionsModal] = useState(false);
  const [showErrorToast, setShowErrorToast] = useState(false);
  const [connectIpsData, setConnectIpsData] = useState<any>({});
  window.document.title = "Payment Summary - Metlife Nepal OPP";
  const timeoutMilliseconds = 5000;
  useEffect(() => {
    let timer: number | undefined;
    if (showErrorToast) {
      timer = window.setTimeout(() => {
        setShowErrorToast(false);
      }, timeoutMilliseconds);
    }

    return () => {
      if (timer) {
        window.clearTimeout(timer);
      }
    };
  }, [showErrorToast]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);

  const submitHandler = async (e: any) => {
    if (!agreeCheckbox) {
      setShowErrorToast(true);
      return false;
    }
    try {
      setLoading(true);
      const signedTermsRes = await signTermsAndCondtions({
        transactionId: paymentId,
        signatureType: TERMS_AND_CONDITION_SIGNATURE,
      });
      if (signedTermsRes?.status === 200) {
        const encodedSuccessUrl = base62encode(PG_SUCCESS_URL);
        const encodedFailiureUrl = base62encode(PG_FAILIURE_URL);
        const res = await initatePayment({
          transactionId: paymentId,
          successUrl: encodedSuccessUrl,
          failureUrl: encodedFailiureUrl,
        });

        if (res?.status === 200) {
          setConnectIpsData({});
          const responseData: any = res?.data?.data ?? {};
          localStorage.removeItem("@user-access"); //token being revoked at BE side.
          if (responseData?.paymentJson) {
            setFormAttribute({
              actionURL: responseData?.paymentUrl,
              method: "POST",
            });
            setConnectIpsData(JSON.parse(responseData?.paymentJson));
            setTimeout(() => {
              document.getElementById("payment-overview-button")?.click();
            }, 300);
          } else {
            window.location.replace(responseData?.paymentUrl);
          }
        }
      }
      setLoading(false);
    } catch (error: any) {
      setLoading(false);
      const errorCode: any = error?.response?.data?.errors?.errorCode ?? "";
      const serverErrorCodes = Object.keys(serverErrorHeadings);
      if (serverErrorCodes.includes(errorCode)) {
        setLoginError(error?.response?.data?.errors);
      }
      console.log("initatePayment", error);
    }
  };
  return (
    <PageContainer customClassName="OTP_Title">
      <div className="PaymentOverview__container">
        <div
          className={`toast-message error ${showErrorToast ? "show" : ""}`}
          id="error-toast-01"
          data-testid="error-toast-message"
        >
          <div className="icon-description">
            <div className="icon">
              <InfoCircleIcon height={24} width={24} fillColor="#DB0A5B" />
            </div>
            <div className="description">{ALERT_TandC}</div>
          </div>

          <div
            className="close"
            data-testid="toast-message-close"
            onClick={() => {
              setShowErrorToast(false);
            }}
          >
            <CloseIcon height={16} width={16} />
          </div>
        </div>
        <div className="PaymentOverview__heading">
          <MetLifeGradientRow />
          <h2>Purpose of Payment - {selectedPayment?.label ?? "Premium"}</h2>
        </div>

        {Object.keys(connectIpsData).length > 0 && (
          <form
            action={formAtrribute?.actionURL ?? ""}
            method={formAtrribute?.method ?? ""}
          >
            {Object.keys(connectIpsData).map((key: any) => (
              <input
                key={key}
                type="hidden"
                name={key}
                id={key}
                value={connectIpsData[key]}
              />
            ))}
            <button
              id="payment-overview-button"
              data-testid="payment-overview-button"
              style={{ display: "none" }}
            />
          </form>
        )}

        <Card>
          <div className="PaymentOverview__card-contents">
            <div className="card-heading">Payment Summary</div>
            <div className="card-content">
              <div className="PaymentOverview__content-row">
                <div className="label">Premium Amount Due</div>
                <div className="value">{formatAmount(payableAmount)}</div>
              </div>
              <div className="PaymentOverview__content-row">
                <div className="label">Currency</div>
                <div className="value">{OPS_CURRENCY}</div>
              </div>
              <div className="PaymentOverview__content-row">
                <div className="label">Transaction ID</div>
                <div className="value">{paymentId ?? ""}</div>
              </div>
              <div className="PaymentOverview__content-row">
                <div className="label">Pay Through</div>
                <div className="value">{item ?? ""}</div>
              </div>
            </div>
            <div className="card-footer">
              <div className="PaymentOverview__content-row">
                <div className="label">
                  <strong>Total</strong>
                </div>
                <div className="value">
                  <strong>{formatAmount(payableAmount)}</strong>
                </div>
              </div>
            </div>
          </div>
        </Card>
        <div className="PaymentOverview__agreement-container">
          <MetLifeCheckbox
            data-testid="agree-terms-checkbox"
            isError={showErrorToast}
            checked={agreeCheckbox}
            onChange={() => setAgreeCheckbox(!agreeCheckbox)}
          />
          <div className="PaymentOverview__agreement-text">
            By clicking this, you are agreeing to the&nbsp;
            <a
              type="button"
              data-testid="show-terms-and-conditions"
              onClick={() => setShowTermsConditionsModal(true)}
            >
              Terms and Conditions
            </a>
            &nbsp;of Metlife Nepal.
          </div>
        </div>
        <div className="PaymentOverview__btn-group">
          <MetLifeButton
            onClick={() => navigate(-1)}
            type="button"
            data-testid="PaymentOverview-back-btn"
            variant="secondary"
          >
            Back
          </MetLifeButton>
          <MetLifeButton
            variant="primary"
            onClick={submitHandler}
            data-testid="PaymentOverview-proceedToPay-btn"
          >
            Next
          </MetLifeButton>
        </div>

        <Modal
          data-testid="terms-and-conditions-modal"
          isVisible={showTermsConditionsModal}
          showModalHeader={false}
          modalContentStyles={{ padding: 0 }}
        >
          <TermsAndConditions
            data-testid="terms-and-conditions-content"
            type={agreeCheckbox}
            onClose={() => setShowTermsConditionsModal(false)}
            onAgree={() => {
              setAgreeCheckbox(true);
              setShowErrorToast(false);
              setShowTermsConditionsModal(false);
            }}
            onDisAgree={() => {
              setAgreeCheckbox(false);
              setShowTermsConditionsModal(false);
            }}
          />
        </Modal>

        <AlertPopup
          data-testid="VerifyOTP__alert-popup-payment-failed"
          alertWrapperCustomClass="PolicyDetails__alert-popup-1"
          isVisible={!!loginError}
          description={loginError?.desc}
          heading={serverErrorHeadings[loginError?.errorCode] || ""}
          primaryBtnText="Login"
          onPressPrimary={() => {
            setLoginError(null);
            logout();
          }}
        />
      </div>
    </PageContainer>
  );
};

export default PaymentOverview;
