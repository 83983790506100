import {
  GENERATE_TOKEN,
  INTROSPECT_TOKEN,
  LOGIN_API,
  REVOKE_TOKEN,
  SEND_OTP,
  VERIFY_OTP,
} from "../constants";
import {
  GenerateTokenTypes,
  LoginPayloadTypes,
  OTPPayloadTypes,
  VerifyOtpTypes,
} from "./auth-types";
import axios from "axios";

const getheadersConfig = () => {
  const username = window.__RUNTIME_CONFIG__.REACT_APP_AUTH_BASE_USERNAME;
  const password = window.__RUNTIME_CONFIG__.REACT_APP_AUTH_BASE_PASSWORD;
  const issuer = window.__RUNTIME_CONFIG__.REACT_APP_API_ISSUER;

  return {
    headers: {
      Authorization: `basic ${btoa(username + ":" + password)}`,
      "Content-Type": "application/x-www-form-urlencoded",
      "X-Bearer-Issuer": issuer,
    },
  };
};

export const login = async (payload: LoginPayloadTypes) => {
  const res = await axios.post(LOGIN_API, payload, getheadersConfig());
  return res;
};

export const verifyOtpAndGenerateToken = async (payload: {
  grant_type: string;
  uuid: string;
  otp_code: number;
}) => {
  const res = await axios.post(GENERATE_TOKEN, payload, getheadersConfig());
  return res;
};

export const sendOTP = async (payload: OTPPayloadTypes) => {
  const res = await axios.post(SEND_OTP, payload);
  return res;
};

export const verifyOtp = async (payload: VerifyOtpTypes) => {
  const res = await axios.post(VERIFY_OTP, payload);
  return res;
};

export const generateToken = async (payload: GenerateTokenTypes) => {
  const res = await axios.post(GENERATE_TOKEN, payload, getheadersConfig());
  return res;
};

export const refreshToken = async (payload: any) => {
  const res = await axios.post(GENERATE_TOKEN, payload, getheadersConfig());
  return res;
};

export const revokeToken = async () => {
  const accessDataJSONString: any = localStorage.getItem("@user-access") ?? null;
  const accessData = JSON.parse(accessDataJSONString);
  let res;
  if (accessData) {
    res = await axios.post(
      REVOKE_TOKEN,
      { token: accessData?.refresh_token ?? "", token_type_hint: "REFRESH_TOKEN" },
      getheadersConfig()
    );
    return res;
  } else {
    throw new Error("Access Token Not Found.");
  }
};

export const introspectToken = async () => {
  const accessDataJSONString = localStorage.getItem("@user-access");
  let res;
  if (accessDataJSONString) {
    const accessData = JSON.parse(accessDataJSONString);
    res = await axios.post(
      INTROSPECT_TOKEN,
      { token: accessData?.refresh_token ?? "" },
      getheadersConfig()
    );
  }
  return res;
};
