import React from "react";
import "@metlife-one-opps/app/src/fonts.css";
import classes from "./TermsAndConditions.module.css";
import { MetLifeButton } from "@metlife-one-opps/metlife-button";
import { GENERAL_TERMS, TANDC_PAY_ONLINE } from "../constants";
import { termsAndConditions } from "../data";
export const TermsAndConditions: React.FC<{
  type: any;
  onDisAgree?: () => void;
  onAgree?: () => void;
  onClose?: () => void;
}> = ({ type, onAgree = () => {}, onClose = () => {}, onDisAgree = () => {} }) => {
  return (
    <div className={classes.container}>
      <div className={classes.innerContainer}>
        <div className={classes.heading}>
          <h3>Terms & Conditions</h3>
          {type && (
            <div className={classes.agreePill}>
              <small>Agreed</small>
            </div>
          )}
        </div>

        <div className={classes.content}>
          <div className={classes.subHeading}>
            <h4>{TANDC_PAY_ONLINE}</h4>
            <span>{GENERAL_TERMS}</span>
          </div>
          <div className={classes.contentInner}>
            <div className={classes.privacyTerms}>
              <ul>
                {termsAndConditions.map((item, index) => {
                  if (index === 3 && item.text.includes("metlife.com.np")) {
                    const parts = item.text.split("metlife.com.np");
                    return (
                      <li key={item.id}>
                        {parts[0]}
                        <a
                          href="https://metlife.com.np"
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          metlife.com.np
                        </a>
                        {parts[1]}
                      </li>
                    );
                  } else {
                    return <li key={item.id}>{item.text}</li>;
                  }
                })}
              </ul>
            </div>
          </div>
        </div>
        <div className={classes.footer}>
          <MetLifeButton onClick={onClose} variant="secondary">
            Close
          </MetLifeButton>
          {!type ? (
            <MetLifeButton type="button" onClick={onAgree} variant="primary">
              Agree
            </MetLifeButton>
          ) : (
            <MetLifeButton type="button" onClick={onDisAgree} variant="secondary">
              Disagree
            </MetLifeButton>
          )}
        </div>
      </div>
    </div>
  );
};

export default TermsAndConditions;
